'use strict';

/**
 * NativeConsole module
 * @class
 * @author Thomas Van Kerckvoorde <thomas@chilli.be>
 */

import anime from 'animejs';

export default class Animations {
	/**
	 * @constructor
	 */
	constructor() {


		anime({
			targets: '.js-anim-up',
			translateY: [-20, 0],
			duration: 800,
			opacity: [0, 1],
			easing: [0.915, 0.005, 0.390, 1.010],
			delay: 200
		});

		anime({
			targets: '.js-anim-up-1',
			translateY: [-20, 0],
			duration: 500,
			opacity: [0, 1],
			easing: [0.915, 0.005, 0.390, 1.010],
			delay: 200
		});

		anime({
			targets: '.js-anim-up-2',
			translateY: [-20, 0],
			duration: 500,
			opacity: [0, 1],
			easing: [0.915, 0.005, 0.390, 1.010],
			delay: 700
		});

		anime({
			targets: '.js-anim-up-3',
			translateY: [-20, 0],
			duration: 800,
			opacity: [0, 1],
			easing: [0.915, 0.005, 0.390, 1.010],
			delay: 1200
		});

		// anime({
		// 	targets: '.js-anim-width',
		// 	duration: 300,
		// 	borderTopWidth: 0,
		// 	delay: 100,
		// 	easing: [0.915, 0.005, 0.390, 1.010]
		// });

		// anime({
		// 	targets: '.js-anim-trans-right',
		// 	translateX: [-20, 0],
		// 	opacity: [0, 1],
		// 	duration: 300,
		// 	width: [0, '100%'],
		// 	delay: 100,
		// 	easing: [0.915, 0.005, 0.390, 1.010]
		// });
	}
}
