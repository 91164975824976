'use strict';

/* libs */
import svg4everybody from 'svg4everybody';

/* custom imports */
import NativeConsole from './modules/native-console';
import Grid from './modules/grid';
import CustomMap from './modules/custommap';
import Navigation from './modules/navigation';
import Animations from './modules/animations';

/**
 * Chilli App
 * @class
 * @author Thomas Van Kerckvoorde <thomas@chilli.be>
 */

class ChilliApp {
	/**
	 * @constructor
	 */
	constructor() {
		new NativeConsole();
		new Navigation();
		new Animations();

		if (document.querySelector('.js-grid')) new Grid();
		if (document.querySelector('.js-map')) new CustomMap();

		/* external svg polyfill, https://github.com/jonathantneal/svg4everybody */
		svg4everybody();

	}


}

/**
 * js vanilla equivalent to jquerys $(document).ready -- fires when DOM is ready (assets not yet ready)
 */
window.addEventListener('load', function() {
	new ChilliApp();
});
