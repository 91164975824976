'use strict';

/**
 * NativeConsole module
 * @class
 * @author Thomas Van Kerckvoorde <thomas@chilli.be>
 */

import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

export default class Grid {
	/**
	 * @constructor
	 */
	constructor() {
		const grid = document.querySelector('.js-grid');
		imagesLoaded(grid, function() {
			new Masonry(grid, {
				// options...
				itemSelector: '.grid-item',
				columnWidth: '.grid-sizer',
				gutter: 0,
				percentPosition: true,
				transitionDuration: 0
			});
		});
		grid.classList.add('grid-visible');
	}
}
